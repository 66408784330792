import FixedCubes from './FixedCubes'
import Arch from './Arch'
import Hyperspace from './Hyperspace'

export default function CubeTunnel() {


  return (
    <>
      <FixedCubes />
      <Arch />
      <Hyperspace />
    </>
  )
}